//Global functions, access anywhere with "this"
//https://stackoverflow.com/questions/42613061/vue-js-making-helper-functions-globally-available-to-single-file-components

export default {
  methods: {

    getApiUrl() {
      //return `https://www.pcbpreflight.com`;
      return location.protocol+'//'+location.hostname+':'+location.port;
    },

    random(min, max) {
    	return Math.random() * (max - min) + min;
    },

    randomInt(min, max) {
    	return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    ucfirst(string) {
      if(string==null) return null;
    	return string.charAt(0).toUpperCase() + string.slice(1);
    },

    validateEmail(email) {
      if(email==null) return false;
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateUrl(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return pattern.test(str);
    },

    formatNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatDecimal(n,places) {
      return parseFloat(Math.round(n*100)/100).toFixed(places);
    },

    formatPhone(ph) {
      if(ph==null) return '';
      ph = ph.replace(/[^0-9]/g, '');
      if(ph.length>10) ph = ph.substr(1,10);
      return ph.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },

    underConstruction() {
      this.$eventHub.$emit('ok-modal',{title:"Under Construction",message:"This part has not been developed yet."});
    }

  }
}
