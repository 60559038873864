<template>
    <main role="main" class="d-flex flex-column" style="min-height:100vh">
        <div style="flex:1 1 auto">
        
            <!-- Navigation -->
            <page-header nav="2"></page-header>
    
            <!-- Body -->
            <div class="container my-3">
                <div class="row">
                    <div class="col lead">
                        
                        <h4 class="my-4 text-center">Online Visualizer: PCB Design Analysis and Collaboration </h4>
                        
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Free Online Viewing of Gerber, Drill, ODB++, IPC-2581, FAB 3000 and more.</h5>
                        <p>PCB Preflight includes a leading cloud-based Viewer and Editing environment for electronic designs.  Meet up with Unlimited Collaborators.</p>
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Easy DFM/DFA Anlysis and Collaboration</h5>
                        <p>Perform critical PCB manufacturing checks most commonly known to cause yield problems and delays during PCB fabrication. Includes powerful Design for Assembly checks, SMT Stencil checks, plus other Advanced DFM Checks: Copper Slivers, Acid Traps, Find Antennas and more.</p>
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Accurate Browsing of DFM Violations</h5>
                        <p>Pinpoint DFM Results and describe violations.  Select any DFM violation and instantly highlight all similiar DFM violations.</p>  
    
                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Netlist Comparison: Find Shorts and Open Nets</h5>
                        <p>Pinpoint any Open or Shorted Nets</p>

                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Visually Compare PCB Design Differences</h5>
                        <p>Easily find all differences between similiar PCB Design revisions.</p>

                        <h5 class="mt-2 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Extract all PCB Fabrication and Assembly dimensions</h5>
                        <p> Automatically extract all PCB design data required to quote and price potential PCB manufacturing costs.</p>
                        <router-link tag="button" class="btn btn-lg btn-primary mt-5" :to="{name:'Signup'}">Get started for free</router-link>

                        <hr class="my-3"/>
                        <h4 class="my-4 text-center">PCB Preflight - API: Simplifying Online PCB Procurement</h4>
            
                        <h5 class="mt-4 text-secondary"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> What is the PCB Preflight API?</h5>
                        <p>Think of <b>PCB Preflight API</b> like the food delivery service <a href="https://www.ubereats.com/" target="_blank">Uber Eats</a>, and you have a hungry family to feed waiting at home.  You could spend the time to buy groceries, drive home, prepare/cook the food, serve meals to everyone, and wash dishes.  Or you could request to have prepared meals delivered to your front door.</p>
                        <p>The PCB Preflight API waits for you to request <b>PCB design information</b>, then extracts and delivers those results back to you (<a href="https://en.wikipedia.org/wiki/JSON" target="_blank">packaged in a JSON file</a>).  
                        PCB Preflight's API will improve your company's Sales and Engineering productivity by drastically reducing CAM time and costs.</p>
                        <hr class="my-2"/>

                        <!-- FAQs -->   
                        <h5 class="mt-4 text-dark">How Difficult is the PCB Preflight API to learn?</h5>
                        <p>Very Easy.  Our goal was to make the PCB Preflight API so easy, anyone without web experience could - <span class="text-success"><b><i>Build an API Solution within 60 Minutes!</i></b></span></p>
                        <p><i class="fa fa-fw fa-arrow-alt-circle-right mr-1 text-info"></i><b>All you need is the ability to <a href="https://www.w3schools.com/js/js_json_parse.asp" target="_blank">parse a JSON file</a>.</b></p>
                        <hr class="my-2"/>

                        <h5 class="mt-4 text-dark">Using PCB Preflight API - in 2 Steps!</h5>
                        <ol>
                        <li>Upload your CAM files (i.e Gerber, NC Drill/Rout, ODB++, DXF, IPC-2581, Netlist).</li>
                        <li>Parse the resulting JSON file.</li>
                        </ol>
                        <hr class="my-2"/>
                
                        <h5 class="mt-4 text-dark">What is the resulting JSON file?</h5>
                        <p><u>PCB Preflight API will ALWAYS return a JSON file.</u>  This <a href="https://www.w3schools.com/js/js_json_parse.asp" target="_blank">JSON file</a> contains all your requested PCB information.  If you have requested files to be delivered (such as Images, PDF reports, ODB++, Gerbers, etc), downloadable URL links will be included.</p>
                        <hr class="my-2"/>

                        <h5 class="mt-4 text-dark">Is it possible to automate Gerber editing, PCB Panelization, SMT Stencil pad adjustments, Etch factors, etc? </h5>
                        <p><i class="fa fa-fw fa-arrow-alt-circle-right mr-1 text-info"></i>Of course, if you wish to get fancy performing Gerber editing, DFM Checks, PCB Panelization, Etch factors -- there is one more step to learn: <router-link tag="a" :to="{name:'DocumentationMatrix'}">Understanding the Numerical Innovations - PCB Matrix format.</router-link></p>            <hr class="my-2"/>

                        <h5 class="mt-4 text-dark">Do you offer a Free API Sandbox? </h5>
                        <p>Yes.  The PCB Preflight API sandbox includes sample files that permit developers to freely test and perform simulated responses - at no charge.</p>

                        <hr class="my-3"/>
                        <h5 class="mt-4 text-info">Need to Test and Visualize PCB Matrix Files on your desktop?</h5>
                        <p>To help with PCB matrix testing and visualization, try using <a href="https://www.numericalinnovations.com/collections/fab-3000-gerber-cam">FAB 3000 (Gerber/CAM)</a>.</p>
                        <router-link tag="button" class="btn btn-lg btn-primary mt-5" :to="{name:'Signup'}">Get started for free</router-link>
            
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <page-footer></page-footer>
        </div>

    </main>
</template>
  
  <script>
  import Common from '../mixins/Common';
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  
  export default {
  
    mixins: [Common],
  
    components: {PageHeader,PageFooter},
  
    mounted() {
        /*
      document.body.style.overflow = "auto";
      this.$store.commit('menu_id',39);
      document.title = 'API Documentation';
      this.updateCrumbText(document.title) ;
      */
      app = this;
      document.title = 'API Documentation';

    },
  
  }
  </script>
  