<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh;background-color:#f0f1f7">

    <div style="flex:1 1 auto">

        <!-- Navigation -->
        <page-header nav="1"></page-header>
        <!-- Navigation -->

        <!-- Masthead -->
        <header class="masthead text-white text-center" style="position:relative">
          <div style="position:absolute;right:20px;bottom:-22px;color:#ffffff;opacity:0.5">
            <img src="/images/landing/robot-happy.svg" width="200" height="200"/>
          </div>
          <div class="overlay"></div>
          <div class="container">
            <div class="row">
              <div class="col-xl-10 mx-auto">
                <h1>DFM, Collaborate, and Ensure PCB Manufacturability</h1>
                <p>Easy to Use. Low Cost. Secure and Reliable.</p>
              </div>
            </div>
          </div>
        </header>
        <!-- Masthead -->

        <!-- Body -->
        <div class="container my-5">
        <div class="row">
            <div class="col lead">
              <h3>GETTING STARTED</h3>
              <p class="lead mb-3">PCBPreflight makes it easy to verify your PCB designs are ready before sending to manufacturing.  PCBPreflight promotes team collaboration with your colleagues and seamless design information exchange with PCB manufacturers.  PCBPreflight is an app available on platforms including: PC (Windows, Linux, Apple), iOS (iPhone, iPads) and Android devices.</p>            
              <hr class="my-4"/>
              <h5 class="mt-2">Ready to get Started with PCBPreflight?</h5>
              <p>First you'll need to <a href="/landing/signup">Signup</a> and create a PCBPreflight account.  <i>The Free STARTER plan is the default.  <a href="https://support.numericalinnovations.com/support/discussions/topics/14000020294">Watch Tutorial > </a><br/><i class="fas fa-fw fa-columns text-primary"></i> Check out several <a href="/landing/pricing">PCBPreflight Plans</a> to compare all features and pricing.</i></p>
              
              <!-- Free Cloud-based Viewer -->
              <hr class="my-4"/>
              <h5 class="mt-2"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Free Viewing of Gerber, Drill, ODB++, IPC-2581, FAB 3000 and more. </h5>
              <ol>
                <li>Go to your <i class="fas fa-fw fa-home"></i> Dashboard, and press button: <b>UPLOAD</b></li>
                <li>Upload your files... </li>
                <li>Press OK.</li>
                <li>Select the newly created Job (from your Dashboard) and it will become visible in PCBPreflight's editor!</li>
              </ol>
              <p class="text-info"><i class="fas fa-fw fa-info"></i>  PCBPreflight's editor includes a leading cloud-based viewing environment for electronic designs.</p>

              <!-- Run a Free DFM Check -->
              <hr class="my-4"/>
              <h5 class="mt-2"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Run a Free DFM Check</h5>
              <ol>
                <li>Go to your <i class="fas fa-fw fa-home"></i> Dashboard, and press button: <b>UPLOAD</b></li>
                <li>Upload your files... </li>
                <li>Select tab <b>DFM Analysis</b> and select the DFM Rule to use.</li>
                <li>Press OK.</li>
              </ol>
              <p class="text-info"><i class="fas fa-fw fa-info"></i> PCBPreflight creates a new Job, and then perform a DFM check.</p>

              <!-- Browse your DFM Results -->
              <hr class="my-4"/>
              <h5 class="mt-2"><i class="fas fa-fw fa-arrow-circle-right text-success"></i> Browse your DFM Results</h5>
              <ol>
                <li>Select any PCBPreflight job from your <i class="fas fa-fw fa-home"></i> Dashboard.</li>
                <li>Select <i class="fas fa-fw fa-bug"></i> DFM Result (from the main toolbar).  <i>The DFM Results pane will now be visible</i></li>
                <li>Pick any DFM result violation (from the DFM Results pane).</li>
                <li>PCBPreflight instantly pinpoints each selected DFM result location. <i> Plus includes a detailed description of the DFM violation.</i></li>
              </ol>
              <p class="text-info"><i class="fas fa-fw fa-info"></i> Selecting any parent DFM violation will highlight all similiar DFM violations.</p>

              <!-- Free Collaborate / User Sharing -->
              <!-- Generate free PDF report -->

            </div>
        </div>
        </div>
        <!-- Body -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

    </main>
  </template>
  
  <script>
    import Common from '../mixins/Common'
  
    export default {
  
      mixins: [Common],
  
      data() {
        return {
        }
      },
  
      mounted() {
        app = this;
        document.title = "Getting Started with PCBPreflight";
      },
  
      methods: {
      }
  
    }
  </script>
  