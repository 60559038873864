<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row">
          <div class="col">
            <p><h3>About Us</h3></p>
            <p class="lead">
              Numerical Innovations, Inc. (located in Las Vegas - USA), develops software to facilitate the manufacturing of Printed Circuit Boards, MEMs, IC, Microwave/RF, and optical components. We are proud to boast a customer base of over 3000 unique companies and universities.  For more information about Numerical Innovations, please visit:
            </p>
            <p class="lead">
              <a href="https://www.numericalinnovations.com/pages/about-us">https://www.numericalinnovations.com/pages/about-us</a>
            </p>
          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'About Us';
    }

  }
</script>
