<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh;background-color:#f0f1f7">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="3"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 col-lg-6">

            <!--SIGNUP TAB-->
            <div class="card">
              <div class="card-header text-center">
                <b>EMAIL VERIFICATION</b>
              </div>
              <div class="card-body">
                <p class="mb-4">
                  We sent you a 6 digit code to the email you provided.
                  Please wait a few minutes to receive it, click the link or enter the code here.
                </p>
                <form>
                  <div class="mb-3">
                    <b>Your Email Address</b>
                    <input ref="ref_account_register_email" @keyup="emailEnterKey" id="text_account_register_email" type="email" class="form-control mt-2" v-model="account_register_email" maxlength="50" style="box-shadow:none" placeholder="Email address you used to sign up" autocomplete="off" spellcheck="false" autocorrect="off" autocapitalize="off">
                  </div>
                  <div class="mb-2">
                    <b>6 Digit Code</b>
                    <input ref="ref_account_register_code" @keyup="codeEnterKey" id="text_account_register_code" type="text" class="form-control mt-2" v-model="account_register_code" maxlength="6" style="box-shadow:none" placeholder="6 digit code we sent you via email" autocomplete="off" spellcheck="false" autocorrect="off" autocapitalize="off">
                  </div>
                </form>
              </div>
              <div class="card-footer py-3">
                <button id="button_register" type="button" class="btn btn-primary btn-lg w-100" @click="verifyClicked">
                  <b>Verify Email</b>
                </button>
                <div class="mt-3 text-center">
                  <router-link tag="a" id="link_already" class="text-muted" :to="{name:'Login',params:{email:account_register_email}}">Already have an account?</router-link>
                </div>
              </div>
            </div>
            <!--SIGNUP TAB-->

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    props: {
      email:String
    },

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        account_register_email: '',
        account_register_code: ''
      }
    },

    mounted() {
      app = this;
      document.title = 'Email Verification';

      let email = this.email || this.$route.query.email || '';
      if(!this.validateEmail(email)) {
        this.account_register_email = '';
        this.$refs.ref_account_register_email.focus();
      }
      else {
        this.account_register_email = email;
        this.$refs.ref_account_register_code.focus();
      }

      let code = this.$route.query.code || '';
      if(code.length!=6) code = '';
      this.account_register_code = code;
    },

    methods: {

      emailEnterKey(e) {
        if(e.keyCode!=13) return;
        this.$refs.ref_account_register_code.focus();
      },

      codeEnterKey(e) {
        if(e.keyCode!=13) return;
        this.verifyClicked();
      },

      verifyClicked() {
        if(this.account_register_email.length==0) return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:"Please enter the email address you had used to sign up."});
        else if(!this.validateEmail(this.account_register_email)) return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:"Please enter a valid email address."});
        else if(this.account_register_code.length==0) return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:"Please enter the 6 digit code you received in your email.  It may take a few minutes to receive that email."});
        else if(this.account_register_code.length!=6) return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:"Please enter a code of atleast 6 characters."});

        let cb = () => {
          this.$eventHub.$emit('loading',true);
          let params = {
            email: this.account_register_email,
            code: this.account_register_code
          };
          let url = this.getApiUrl();
          $.post(`${url}/api/v1/email/verify`,params).done(response => {
            if(response.ok==0) {
              this.$eventHub.$emit('loading',false);
              if(response.err.message==null) return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:"Could not verify please check your connection and try again."});
              else return this.$eventHub.$emit('ok-modal',{title:'Verification Error',message:response.err.message});
            }
            else {
              this.account_register_name = '';
              this.account_register_code = '';
              window.location = '/admin';
            }
          }).fail((xhr,status,err)=>{
            this.$eventHub.$emit('loading',false);
            this.$eventHub.$emit('ok-modal',{title:this.$t("connection_error_title"),message:this.$t("connection_error_message")});
          });
        }

        //Email sanity check for gmail and yahoo
        if((this.account_register_email.indexOf('@gmail')>0||this.account_register_email.indexOf('@yahoo')>0) && !this.account_register_email.endsWith('.com')) {
          this.$eventHub.$emit('ok-modal',{title:'Email Check',message:`You typed your email as <kbd>${this.account_register_email}</kbd> which may be invalid.  Are you sure you typed that email is correctly?`,callback:cb});
        }
        else cb();
      },


    }

  }
</script>
