<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh;background-color:#f0f1f7">

    <div style="flex:1 1 auto;position:relative">
      
      <!-- Navigation -->
      <page-header nav="3"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">

        <div class="row justify-content-center">
          <div class="col-12 col-md-8 col-lg-6">

            <!--SIGNUP TAB-->
            <div class="card">
              <div class="card-header text-center">
                <b>Getting started</b>
              </div>
              <div class="card-body">

                <!--SOCIALS-->
                <!-- <div class="row mb-3">
                  <div class="col-12 col-md-4 pr-md-0">
                    <div class="mb-2">
                      <a id="button_login_facebook" class="btn btn-block btn-lg text-white text-center text-nowrap" style="background-color:#254f7b;padding:8px;font-size:0.95em" href="javascript:;" @click="loginFacebook">
                        <div style="display:inline-block;width:24px"><i class="fab fa-facebook-f fa-fw"></i></div>
                        Facebook
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="mb-2">
                      <a id="button_login_twitter" class="btn btn-block btn-lg text-white text-center text-nowrap" style="background-color:#18a9cf;padding:8px;font-size:0.95em" href="javascript:;" @click="loginTwitter">
                        <div style="display:inline-block;width:24px"><i class="fab fa-twitter fa-fw"></i></div>
                        Twitter
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 pl-md-0">
                    <div class="mb-2">
                      <a id="button_login_google" class="btn btn-block btn-lg text-white text-center text-nowrap" style="background-color:#cc4034;padding:8px;font-size:0.95em" href="javascript:;" @click="loginGoogle">
                         <div style="display:inline-block;width:24px"><i class="fab fa-google-plus-g fa-fw"></i></div>
                         Google
                      </a>
                    </div>
                  </div>
                </div> -->
                <!--SOCIALS-->

                <!--EMAIL SIGNUP-->
                <form>
                  <div class="mb-3">
                    <b>Your Full Name</b>
                    <input id="text_account_register_name" type="text" class="form-control mt-2" v-model="account_register_name" maxlength="50" style="box-shadow:none" placeholder="Your first and last name">
                  </div>
                  <div class="mb-3">
                    <b>Your Email Address</b>
                    <input id="text_account_register_email" type="email" class="form-control mt-2" v-model="account_register_email" maxlength="50" style="box-shadow:none" placeholder="Your email address">
                  </div>
                  <div class="mb-3">
                    <b>Password</b> <i class="fa fa-lock ml-2"></i>
                    <input id="text_account_register_password" type="password" class="form-control mt-2" v-model="account_register_password" maxlength="30" style="box-shadow:none" placeholder="Password" autocomplete="off">
                  </div>
                  <div>
                    <b>Confirm Password</b> <i class="ml-2 fa fa-lock"></i>
                    <input id="text_account_register_password_confirm" type="password" class="form-control mt-2" v-model="account_register_password_confirm" maxlength="30" style="box-shadow:none" placeholder="Confirm password" v-on:keyup.enter="signUp" autocomplete="off">
                  </div>
                </form>
                <!--EMAIL SIGNUP-->

              </div>
              <div class="card-footer py-3">
                
                <button id="button_register" type="button" class="btn btn-primary btn-lg w-100" @click="signUpClicked" style="position:relative;height:64px;overflow:hidden">
                  <div style="position:absolute;right:12px;bottom:-8px;color:#ffffff;opacity:0.25;">
                    <img src="/images/landing/robot-happy.svg" width="70" height="70"/>
                  </div>
                  <div style="position:absolute;left:12px;bottom:-13px;color:#ffffff;opacity:0.25;">
                    <img src="/images/landing/robot-arm.svg" width="70" height="70"/>
                  </div>
                  <b>Create Account</b>
                </button>

                <div class="mt-3 text-center">
                  <router-link tag="a" id="link_already" class="text-muted" :to="{name:'Login',params:{email:account_register_email}}">Already have an account?</router-link>
                </div>
                <div class="mt-0 text-center">
                  <router-link tag="a" id="link_phone_verify" class="text-muted" :to="{name:'EmailVerification',params:{email:account_register_email}}">Got an email verification code?</router-link>
                </div>
              </div>
            </div>
            <!--SIGNUP TAB-->

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        account_register_name: '',
        account_register_email: '',
        account_register_password: '',
        account_register_password_confirm: ''
      }
    },

    mounted() {
      app = this;
      document.title = 'Sign Up';

      let email = this.email || this.$route.query.email || '';
      if(!this.validateEmail(email)) email = '';
      this.account_register_email = email;
    },

    methods: {

      onHomeClicked() {
        window.location = '/';
      },

      // loginFacebook() { //MUST BE PRETAMENU DOMAIN, DONT CHANGE!!
      //   this.$eventHub.$emit('loading',true);
      //   window.location = '/api/v1/oauth/facebook';
      // },

      // loginTwitter() { //MUST BE PRETAMENU DOMAIN, DONT CHANGE!!
      //   this.$eventHub.$emit('loading',true);
      //   window.location = '/api/v1/oauth/twitter';
      // },

      // loginGoogle() { //MUST BE PRETAMENU DOMAIN, DONT CHANGE!!
      //   this.$eventHub.$emit('loading',true);
      //   window.location = '/api/v1/oauth/google';
      // },

      signUpClicked() {
        if(this.account_register_name.length==0) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please enter your first and last name."});
        else if(this.account_register_email.length==0) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please enter your email address."});
        else if(!this.validateEmail(this.account_register_email)) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please enter a valid email address."});
        else if(this.account_register_password.length==0) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please enter a password."});
        else if(this.account_register_password.length<6) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please enter a password of atleast 6 characters."});
        else if(this.account_register_password!=this.account_register_password_confirm) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Please make sure both passwords match."});

        let cb = () => {
          this.$eventHub.$emit('loading',true);
          let params = {
            user_name: this.account_register_name,
            email: this.account_register_email,
            password: this.account_register_password
          };
          let url = this.getApiUrl();
          $.post(`${url}/api/v1/email/register`,params).done(response => {
            this.$eventHub.$emit('loading',false);
            if(response.ok==0) {
              if(response.err.message==null) return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:"Could not register new customer please check your connection and try again."});
              else return this.$eventHub.$emit('ok-modal',{title:'Registration Error',message:response.err.message});
            }
            else {
              //this.$store.state.session.cd = null;
              this.$router.push({name:'EmailVerification',params:{email:this.account_register_email}});
              this.account_register_name = '';
              this.account_register_email = '';
              this.account_register_password = this.account_register_password_confirm = '';
            }
          }).fail((xhr,status,err)=>{
            this.$eventHub.$emit('loading',false);
            this.$eventHub.$emit('ok-modal',{title:this.$t("connection_error_title"),message:this.$t("connection_error_message")});
          });
        }

        //Email sanity check for gmail and yahoo
        if((this.account_register_email.indexOf('@gmail')>0||this.account_register_email.indexOf('@yahoo')>0) && !this.account_register_email.endsWith('.com')) {
          this.$store.commit('confirmModal',{title:'Email Check',message:`You typed your email as <kbd>${this.account_register_email}</kbd> which may be invalid.  Are you sure you typed that email is correctly?`,callback:cb});
        }
        else cb();
      },


    }

  }
</script>
