import Vue from 'vue'
import UnderConstruction from './views/UnderConstruction.vue'
import Main from './views/Main.vue'
import Terms from './views/Terms.vue'
import Privacy from './views/Privacy.vue'
import GettingStarted from './views/GettingStarted.vue'
import About from './views/About.vue'
import Demo from './views/Demo.vue'
import DfmDemo from './views/DfmDemo.vue'
import Login from './views/Login.vue'
import LoginStudent from './views/LoginStudent.vue'
import Signup from './views/Signup.vue'
import EmailVerification from './views/EmailVerification.vue'
import Contact from './views/Contact.vue'
import Documentation from './views/Documentation.vue'
import DocumentationApi from './views/DocumentationApi.vue'
import Pricing from './views/Pricing.vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter);

const MIN_DELAY = 0;

const router = new VueRouter({
  mode:'history',
  routes: [
    {path:'/landing/terms',name:'Terms',component: Terms},
    {path:'/landing/doc',name:'Documentation',component: Documentation},
    {path:'/landing/documentationapi',name:'DocumentationApi',component: DocumentationApi},
    {path:'/landing/demo',name:'Demo',component: Demo},
    {path:'/landing/dfmdemo',name:'DfmDemo',component: DfmDemo},
    {path:'/landing/pricing',name:'Pricing',component: Pricing},
    {path:'/landing/contact',name:'Contact',component: Contact},
    {path:'/landing/privacy',name:'Privacy',component: Privacy},
    {path:'/landing/gettingstarted',name:'GettingStarted',component: GettingStarted},
    {path:'/landing/about',name:'About',component: About},
    {path:'/landing/login',name:'Login',component: Login, props:true},
    {path:'/landing/student',name:'LoginStudent',component: LoginStudent},
    {path:'/landing/signup',name:'Signup',component: Signup, props:true},
    {path:'/landing/signup/verify',name:'EmailVerification',component: EmailVerification, props:true},
    {path:'/landing',name:'Main',component: Main},
    {path:'/',name:'Main',component: Main} //Change to Main when live
    //{path:'/',name:'UnderConstruction',component: UnderConstruction} //Change to Main when live
  ],
  scrollBehavior (to, from, savedPosition) { //Every page scroll to top (instant)
    return { x: 0, y: 0 }
  }
});

//Show loading overlay
router.beforeResolve((to,from,next)=>{
  if(app!=null && to.name) app.$eventHub.$emit('loading',true);
  if(MIN_DELAY>0) setTimeout(()=>next(),MIN_DELAY);
  else next();
})

//Hide loading overlay
router.afterEach((to,from)=>{
  if(app!=null) app.$eventHub.$emit('loading',false);
})

export default router;
