//This turns the store to a plugin

import store from './store'

export default {
  store,
  install (Vue, options) {
    Vue.prototype.$store = store
  }
}
