<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">
      
      <!-- Navigation -->
      <page-header nav="1"></page-header>

        <!-- Body -->
        <div class="container my-5">
          <div class="row">
            <div class="col lead">

              <p class="lead"><span class="font-weight-bold text-info">PCB Preflight</span> makes it easy to verify all PCB designs are ready for manufacturing and it's <b>available everywhere on PC (Windows, Linux, Apple), iOS (iPhone, iPads) and Android devices.</b></p>            
              <p class="lead mb-3">Adding PCB Preflight to your design flow, will result in improved PCB Manufacturability, Delivery times and lower overall costs for your prototypes and NPIs!</p>            
              <h5  class="mt-4 mb-1">PCB Preflight has two primary products: <b>Visualize PCB</b> and <b>REST API</b></h5>
              <ul>
                <li><b>Visualize PCB</b> (Design Analysis + Collaborate)</li>
                <li><b>REST API</b> (easy to build PCB Manufacturing apps)</li>
              </ul>              
              <hr class="my-4"/>
              <router-link tag="button" class="btn btn-lg btn-primary mt-5" :to="{name:'Signup'}">Get started for free</router-link>

            </div>
          </div>
        </div>

      <!-- Footer -->
      <page-footer></page-footer>

    </div>

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'Documentation';
    }

  }
</script>
