<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="6"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container mt-5 pb-5">
        <div class="row">
          <div class="col">
            <dfm-check :id="id"></dfm-check>
          </div>
        </div>
      </div>            
      <!-- Body -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import DfmCheck from '../components/DfmCheck.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter,DfmCheck},

    data() {
      return {
        id: '49TEQg'
      }
    },

    mounted() {
      app = this;
      document.title = 'DFM Demo';
    },

    methods: {
    }

  }
</script>
