<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row">
          <div class="col lead">

            <h3>Terms of Service</h3>
            <p>By accessing and using the PCB Preflight service you confirm and are bound by the terms and conditions contained in the Terms Of Service outlined below. These terms apply to the entire website and any API usage. This service is operated by <a href="https://www.numericalinnovations.com/">Numerical Innovations, Inc (USA)</a>. We reserve the right to correct or amend these Terms of Use.</p>

            <h5 class="mt-4">Allowed Usage</h5>
            <p>This service offers functionality to verify, convert and modify files of different file formats. This service is provided via webinterface, API and mobile apps. You agree to comply the policies and limitations concerning use of the PCB Preflight service, including, without limitation, the maximum consumed time ("conversion minutes") of processing done through the service, the maximum number of files that a user may upload through the service, the maximum size of the files that a user may process through the service. You agree not to bypass these policies by any means (e.g. creating multiple accounts).</p>

            <h5 class="mt-4">Subscriptions</h5>
            <p>Subscriptions add a monthly credits to your account. Unused minutes will expire at the end of the billing period. All payments are not refundable. The subscription will be activated on your account as soon as we receive your payment and will be renewed 1 month after your payment. You can cancel your subscription at anytime on the PCB Preflight website, which will take effect at the end of the billing period. You can change your subscription to a different plan at anytime, but the remaining minutes of the current billing period will expire.</p>

            <h5 class="mt-4">Overages</h5>
            <p>By enabling the "Overages" option, you agree to be charged additionally if your monthly minutes usage exceeds the subscription level allotment you have chosen. You are responsible for monitoring your account in order to prevent unwanted payments. The Overages can be disabled at anytime on your user dashboard.  If the minutes overages option is disabled and subscription minutes are exceeded, the service will be temporarily suspended until either the overage option is enabled again, or when the monthly subscription has been renewed.  By default, Overages are enabled to prevent any possible down-times or file availability.</p>

            <h5 class="mt-4">Availability of Service (SLA)</h5>
            <p>We will use commercially reasonable efforts to make the service each available and will do everything we can to achieve 100% uptime.  We will never achieve 100% uptime, but when we fall short, we'll explain why and how we'll do better next time.</p>

            <h5 class="mt-4">Refunds</h5>
            <p>The service begins when we confirm your initial payment. We will charge you the fee stated at the time of purchase monthly until you cancel. Once the fee has been charged each month, that charge is final and no refund is available.</p>

            <h5 class="mt-4">Responsibility for Data</h5>
            <p>While we try to ensure that the service is error free, we cannot guarantee that your files will not be damaged, deleted or lost. You acknowledge that you have a copy of all files and PCB Preflight will not be responsible for any failure caused by files, sent to PCBPreflight.com</p>

            <h5 class="mt-4">Exclusion of Warranties</h5>
            <p>Numerical Innovations does not warrant that PCB Preflight is free from bugs or errors. PCB Preflight and related materials are provided to users "AS IS" and Numerical Innovations disclaims all other warranties, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose and noninfringment of third party rights with respect to PCB Preflight and/or related materials. In no event will Numerical Innovations, or its licensors be liable for indirect, consequential, incidental or special damages even if Numerical Innovations has been advised of the possibility of such damages.</p>

            <h5 class="mt-4">Copyright Policy</h5>
            <p>You are responsible for the data (e.g. files, URLs) which is sent to the PCB Preflight service. PCB Preflight does not monitor customer content.</p>

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';

  export default {

    components: {PageHeader,PageFooter},

    mounted() {
      app = this;
      document.title = 'Terms and Conditions, Use';
    }

  }
</script>
