<template>
  <!-- KEEP as a Nice Demo Tutorial - to give people ideas about the API  -->
  <div>
    <div v-if="result!=null" class="mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <!--<h3 class="mb-3">DFM Check <span class="text-muted">#{{result.job.job_id_hash}}</span></h3>-->
        <h3 class="mb-3">PCB Preflight Check Demo</h3>
        <div v-if="pdf_url!=null"><a :href="pdf_url" class="link" target="_blank">Download PDF <i class="far fa-file-pdf"></i></a></div>
      </div>

      <p class="lead mb-4">
        This is an example of a DFM check you can run using our Online DFM Check also available when you sign up for our service.
        Click each section to dig deeper and analyze potentional issues before commiting to a final design.
        You can also download a PDF version for an easy printout.
        Also check out a demo of our <router-link tag="a" :to="{name:'Demo'}">Cloud API</router-link>.
      </p>

      <div class="layer-header py-2 px-3 text-white d-flex justify-content-between cursor-pointer" v-b-toggle="'accordion-0'">
        <span><b>VIEW</b>: Top & Bottom</span>
        <div>
          <i v-if="carets['main']==true" class="fa fa-fw fa-caret-down"></i>
          <i v-else class="fa fa-fw fa-caret-right"></i>
        </div>
      </div>
      <b-collapse id="accordion-0" visible accordion="my-accordion" v-model="carets['main']" role="tabpanel" class="pt-3 pb-2">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-sm-6">
              <div class="card">
                <div class="card-header thumbnail-header">
                  Top View
                </div>
                <div class="card-body">
                  <a href="javascript:;" @click="imageClicked(top_url)"><b-img-lazy :src="top_url" class="img-fluid"/></a>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card">
                <div class="card-header thumbnail-header">
                  Bottom View
                </div>
                <div class="card-body">
                  <a href="javascript:;" @click="imageClicked(bottom_url)"><b-img-lazy :src="bottom_url" class="img-fluid"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <div v-if="result!=null" v-for="(layer,i) in layers" class="mb-3">
      <div class="layer-header py-2 px-3 text-white d-flex justify-content-between cursor-pointer" v-b-toggle="'accordion-layer-'+i">
        <span>
          <span class="mr-2"><b>LAYER:</b> {{layer.name}}</span>
          <span v-if="layer.error_medium>0" class="badge badge-pill badge-primary level-medium mr-1" v-b-tooltip.hover :title="layer.error_medium+' medium'">{{layer.error_medium}}</span>
          <span v-if="layer.error_elevated>0" class="badge badge-pill badge-primary level-elevated mr-1" v-b-tooltip.hover :title="layer.error_elevated+' elevated'">{{layer.error_elevated}}</span>
          <span v-if="layer.error_critical>0" class="badge badge-pill badge-primary level-critical mr-1" v-b-tooltip.hover :title="layer.error_critical+' critical'">{{layer.error_critical}}</span>
        </span>
        <div>
          <span class="small mr-2">{{layer.error_types.length}}</span>
          <i v-if="carets['layer'+i]==true" class="fa fa-fw fa-caret-down"></i>
          <i v-else class="fa fa-fw fa-caret-right"></i>
        </div>
      </div>
      <b-collapse :id="'accordion-layer-'+i" :visible="i==0" :accordion="'accordion-layer-'+i"  v-model="carets['layer'+i]" role="tabpanel" class="pt-3 pb-0">

        <div v-for="(error_type,k) in layer.error_types" class="mb-2">
          <div class="py-1 px-3 d-flex justify-content-between cursor-pointer" :class="'level-'+error_type.level" v-b-toggle="'accordion-type-'+i+'-'+k">
            <span><b>{{error_type.level.toUpperCase()}}:</b> {{error_type.name}}</span>
            <div>
              <span class="small mr-2">{{error_type.markers.length}}</span>
              <i v-if="carets['type'+i+'-'+k]==true" class="fa fa-fw fa-caret-down"></i>
              <i v-else class="fa fa-fw fa-caret-right"></i>
            </div>
          </div>
          <b-collapse :id="'accordion-type-'+i+'-'+k" :visible="k==0" :accordion="'accordion-type-'+i+'-'+k" v-model="carets['type'+i+'-'+k]" role="tabpanel" class="pt-3">
            <div class="container-fluid p-0">
              <div class="row">

                <div class="col-lg-3 col-md-4 col-sm-6 mb-3" v-for="(marker,j) in error_type.markers">
                  <div class="card">
                    <div class="card-header thumbnail-header">
                      <b-badge pill class="mr-2">{{j+1}}</b-badge> <b>X:</b> {{marker.x | numeral('0.000')}} <b>Y:</b> {{marker.y | numeral('0.000')}}
                    </div>
                    <div class="card-body p-0">
                      <a href="javascript:;" @click="imageClicked(marker.thumbnail)"><b-img-lazy :src="marker.thumbnail" fluid-grow/></a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </b-collapse>
        </div>

      </b-collapse>
    </div>
  </div>
</template>

<style>
  .cursor-pointer {
    cursor: pointer;
  }
  .link {
    color: #00417f !important;
  }
  .layer-header {
    background-color: #438bcb !important;
    font-size: 1.00em;
    font-weight: bold;
    color: white;
  }
  .thumbnail-header {
    padding: .40rem 0.80rem !important;
    line-height: 1.5em;
    font-size: 0.90em;
    color: #808080;
  }
  .level-critical {
    background-color:#ff6464 !important;
    font-size: 0.90em;
    color:white;
  }
  .level-elevated {
    background-color:#ffa500 !important;
    font-size: 0.90em;
    color:white;
  }
  .level-medium {
    background-color:#f3c330 !important;
    font-size: 0.90em;
    color:white;
  }
</style>

<script>
  import Common from '../mixins/Common';

  export default {

    props: ['id'],

    mixins: [Common],

    data() {
      return {
        moment,
        ucfirst,
        result: null,
        dfm: null,
        layers: null,
        top_url: null,
        bottom_url: null,
        pdf_url: null,
        carets: {
          main: true
        }
      }
    },

    created() {app=this;},

    mounted() {

      //Get data
      this.$eventHub.$emit('loading',true);
      $.get(`/api/v1/demo/job/${this.id}`).done(response=> {
        this.$eventHub.$emit('loading',false);
        if(response.ok==0) return this.$eventHub.$emit('ok-modal',response.err);
        this.result = response.result;

        let layers = [];
        if(this.result.job.settings.output!='files') {
          this.$eventHub.$emit('ok-modal',{title:"DFM Report",message:"A DFM report is not available for this job."});
        }
        else if(this.result.job.cam_json!=null && this.result.job.cam_json.cam_checks!=null) {
          this.dfm = this.result.job.cam_json.cam_checks[0];

          //Separate markers to layers
          let markers = {}
          for(let marker of this.dfm.markers) {
            if(markers[marker.layer]==null) markers[marker.layer] = [];
            markers[marker.layer].push(marker);
          }
          Object.keys(markers).forEach(key=>layers.push({
            name:key,
            markers:markers[key],
            error_medium: markers[key].filter(n=>n.error_level=='medium').length,
            error_elevated: markers[key].filter(n=>n.error_level=='elevated').length,
            error_critical: markers[key].filter(n=>n.error_level=='critical').length
          }));

          //Each layer subcategory of error_type
          for(let layer of layers) {
            let error_types = {};
            for(let marker of layer.markers) {
              if(error_types[marker.error_type]==null) error_types[marker.error_type] = [];
              error_types[marker.error_type].push(marker);
            }
            let error_types_array = [];
            Object.keys(error_types).forEach(key=>{
              error_types_array.push({
                name: key,
                sort: this.levelSort(error_types[key][0].error_level),
                level: error_types[key][0].error_level,
                markers: error_types[key]
              });
            });
            error_types_array.sort((a,b)=>{return a.sort-b.sort});
            layer.error_types = error_types_array;
          }

          this.layers = layers;
          this.top_url = this.result.job.settings.outputs.find(n=>n.path.includes('realTop')).url;
          this.bottom_url = this.result.job.settings.outputs.find(n=>n.path.includes('realBottom')).url;

          //Find PDF
          let pdf = this.result.job.settings.outputs.find(n=>n.path.includes('.pdf'));
          if(pdf!=null) this.pdf_url = pdf.url;
          else this.pdf_url = null;
        }
        else {
          this.$eventHub.$emit('ok-modal',{title:"DFM Report",message:"A DFM report is not available for this job."});
        }

      }).fail(()=>{
        this.$eventHub.$emit('loading',false);
        this.$eventHub.$emit('ok-modal',{title:this.$t("connection_error_title"),message:this.$t("connection_error_message")});
      });
    },

    methods: {
      imageClicked(url) {
        $.featherlight(url);
      },

      downloadJob(url) {
        window.location = url;
      },

      levelSort(lvl) {
        if(lvl=='medium') return 0;
        else if(lvl=='elevated') return 1;
        else if(lvl=='critical') return 2;
      }
    }
  }
</script>
