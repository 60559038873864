<template>
  <div id="okModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{this.title}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p v-html="this.message"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="okClicked" data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {

    data() {
      return {
        title: '',
        message: '',
        callback: null
      }
    },

    mounted() {
      this.$eventHub.$on('ok-modal',obj => {
        this.title = obj.title;
        this.message = obj.message;
        this.callback = obj.callback;
        this.$nextTick(()=>{$('#okModal').modal('show')});
      })
      this.$eventHub.$on('ok-modal-close',() => {
        $('#okModal').modal('hide');
      })
    },

    methods: {

      okClicked() { //Generic confirm modal
        $('#okModal').modal('hide');
        if(this.callback!=null) {
          this.callback();
          this.callback = null;
        }
      }

    }
  }
</script>
