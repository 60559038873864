<template>
  <main role="main">
    <div class="p-4">Under Construction.</div>
  </main>
</template>

<script>
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    data() {
      return {
      }
    },

    mounted() {
      app = this;
      document.title = "PCB Preflight - Under Construction";
    },

    methods: {
    }

  }
</script>
