<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="5"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container mt-5">
        <div class="row">
          <div class="col">

            <!--WIZARD NAV-->
            <div class="d-flex justify-content-between mb-3">
              <div>
                <h3>Live Cloud API Demo <span class="text-muted">- Step {{step}}/3</span></h3>
              </div>
              <div v-if="step==1">
                <button type="button" class="btn btn-primary" @click="step1Next">Next</button>
              </div>
              <div v-else-if="step==2">
                <button type="button" class="btn btn-outline-primary mr-2" @click="step2Back">Back</button>
                <button type="button" class="btn btn-primary" @click="step2Next">Next</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-primary" @click="step2Back">Reset</button>
              </div>
            </div>
            <!--WIZARD NAV-->

            <p class="lead mb-4">
              In this example you can upload multiple layers and choose the type of layer for each one.
              Select Gerber and Drill files.  Choose a layer type for each file.  Then the image results will be shown.
              Check out our <a href="https://github.com/NumericalInnovations/clouddfm-examples" target="_blank">GitHub</a> repository for more examples.
              This demo is built using a very simple UI, which can easily be improved using any Web Framework UI.
              Also check out a demo of our <router-link tag="a" :to="{name:'DfmDemo'}">PCB Preflight Check</router-link>.
            </p>

            <div v-if="step==1">
              <form id="form1" class="d-flex justify-content-between align-items-center bg-light border mt-3 px-3" style="border-radius:16px">
                <div class="align-self-center text-nowrap"><div class="m-2 py-1"><b>Upload Files</b></div></div>
                <div class="flex-grow-1">
                  <div class="m-2 pl-2 py-1">
                    <b-form-file id="files" v-model="files" placeholder="Choose multiple files or drop it here..." drop-placeholder="Drop multiple files here..." accept=".drl,.pho,.gbr,.cam,.art,.zip" required multiple></b-form-file>
                  </div>
                </div>
              </form>
            </div>

            <div v-else-if="step==2">
              <div v-for="(n,i) of layers" class="d-flex justify-content-between align-items-center bg-light border mt-3 px-3" style="border-radius:16px">
                <div class="align-self-center text-nowrap"><h5 class="mt-0 mb-1"><span class="badge badge-primary mr-2">{{i+1}}</span></h5></div>
                <div class="align-self-center mx-2" style="width:120px;overflow:hidden"><b>{{n.name}}</b></div>
                <div class="flex-grow-1">
                  <div class="m-2 pl-2 py-1">
                    <b-form-select v-model="n.type" :options="LAYER_TYPES"></b-form-select>
                  </div>
                </div>
                <div class="m-2 pl-2 py-1">
                  <div class="btn-group">
                    <button class="btn btn-outline-primary btn-sm" @click="upLayer(i)" :disabled="layers.length==0 || i==0"><i class="fa fa-arrow-up"></i></button>
                    <button class="btn btn-outline-primary btn-sm" @click="downLayer(i)" :disabled="layers.length==0 || i==layers.length-1"><i class="fa fa-arrow-down"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="row">
                <div v-for="n of result" class="col-sm-6 col-md-4 col-lg-3">
                  <div class="bg-light border p-2 my-3">
                    <img :src="n.url" class="img-fluid">
                    <div class="text-center text-muted small mt-1" style="overflow:hidden">{{n.path}}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- Body -->

    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        step: 1,
        files: null,
        layers: [],
        result: null,
        LAYER_TYPES: this.$store.state.layer_types.map(n=>{return {text:n.name,value:n.id}})
      }
    },

    mounted() {
      app = this;
      document.title = 'Live Demo';
    },

    methods: {
      step1Next() {
        if(this.files.length==0) return this.$eventHub.$emit('ok-modal',{title:"Files Required",message:"Please choose one or more files as layers."});

        this.layers = [];
        for(let i=0;i<this.files.length;i++) this.layers.push({index:i,name:this.files[i].name,sort:(i+1),type:'TOP',active:true});
        this.step = 2;
      },
      upLayer(i) {
        this.layers.splice(i-1,0,this.layers.splice(i,1)[0]);
        this.$forceUpdate();
      },
      downLayer(i) {
        this.layers.splice(i+1,0,this.layers.splice(i,1)[0]);
        this.$forceUpdate();
      },
      step2Back() {
        this.files = [];
        this.step = 1;
      },
      step2Next() {

        //Sort layers ascending
        this.layers.sort((a,b)=>{return a-b});

        //Create FM6 file
        let c = 0;
        var fm6 = '';
        fm6 += 'OPTIONS {\n';
        fm6 += '  fab-generate_border\n';
        fm6 += '}\n';
        fm6 += 'JOB {\n';
        fm6 +='   NAME=testgerber\n';
        for(let i=0;i<this.layers.length;i++) {
          let layer = this.layers[i];
          if(layer.active==false) continue;
          let file = layer.name;
          if(file.length==0) break;
          let filename = file.split('\\').pop();
          let layer_type = layer.type;
          fm6 += '   LAYER=,'+filename+';'+layer_type+'\n';
          c++;
        }
        fm6 += '}\n';
        fm6 += 'EXPORT_IMAGE {\n'
        fm6 += '   CELL=active\n'
        fm6 += '   FOLDER=myGerberViewerImages\n'
        fm6 += '   WIDTH=1024\n'
        fm6 += '   BACKGROUND=white\n'
        fm6 += '}\n'
        fm6 += 'EXPORT_IMAGE {\n'
        fm6 += '   CELL=testgerber\n'
        fm6 += '   FILE=myGerberViewerImages/all.jpg\n'
        fm6 += '   WIDTH=1024\n'
        fm6 += '}\n'

        //Send form to PCB Preflight
        if(c==0) return this.$eventHub.$emit('ok-modal',{title:"Files Required",message:"Please choose one or more files as layers."})

        let fd = new FormData();
        for(let i=0;i<this.files.length;i++) fd.append('files',this.files[i]);
        fd.append('fm6',fm6);
        fd.append('output','files');
        fd.append('async',false);
        fd.append('save_input',false);
        fd.append('send_email',false);
        fd.append('timeout',5);

        this.$eventHub.$emit('loading',true);
        //app.$eventHub.$emit('loading',true);
        this.result = null;
        this.$forceUpdate();
        let url = this.getApiUrl();
        $.ajax({
          url: `${url}/api/v1/bypass/demo`,
          type: 'POST',
          data: fd,
          cache: false,
          contentType: false,
          processData: false,
          xhr: () => {
            let myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', function (e) {
                if (e.lengthComputable) console.log(`Uploading ${e.loaded}/${e.total}`);
              }, false);
            }
            return myXhr;
          },
          error: (err) => {
            console.log(err);
            this.$eventHub.$emit('loading',false);
            return this.$eventHub.$emit('ok-modal',{title:"Error",message:err.statusText});
          }
        }).done(response => {
          app.$eventHub.$emit('loading',false);
          if(response.ok==0) {
            return this.$eventHub.$emit('ok-modal',response.err);
          }
          else {
            this.step = 3;
            this.result = response.result.outputs.filter(n=>{return n.ext=='jpg'||n.ext=='png'});
            console.log(JSON.stringify(this.result));
          }
        });

      }
    }

  }
</script>
