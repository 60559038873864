<template>
  <main role="main" class="d-flex flex-column" style="min-height:100vh;background-color:#f0f1f7">

    <div style="flex:1 1 auto">

      <!-- Navigation -->
      <page-header nav="4"></page-header>
      <!-- Navigation -->

      <!-- Body -->
      <div class="container my-5">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 col-lg-6">

            <!--SIGNUP TAB-->
            <div class="card">
              <div class="card-header text-center">
                <b>STUDENT LOGIN</b>
              </div>
              <div class="card-body">

                <!--EMAIL SIGNUP-->
                <form>
                  <div class="mb-3">
                    <b>Your Login Code</b>
                    <input ref="ref_login_code" @keyup="loginCodeEnterKey" id="text_account_login_code" type="text" class="form-control mt-2" v-model="login_code" maxlength="50" style="box-shadow:none" placeholder="Your 6 character login code" autocomplete="off" spellcheck="false" autocorrect="off" autocapitalize="off">
                  </div>
                </form>
                <!--EMAIL SIGNUP-->

              </div>
              <div class="card-footer py-3">

                <button id="button_register" type="button" class="btn btn-primary btn-lg w-100" @click="loginClicked" style="position:relative;height:64px;overflow:hidden">
                  <div style="position:absolute;right:12px;bottom:-8px;color:#ffffff;opacity:0.25;">
                    <img src="/images/landing/robot-happy.svg" width="70" height="70"/>
                  </div>
                  <div style="position:absolute;left:12px;bottom:-13px;color:#ffffff;opacity:0.25;">
                    <img src="/images/landing/robot-arm.svg" width="70" height="70"/>
                  </div>
                  <b>Student Login</b>
                </button>

                <div class="mt-3 text-center">
                  <router-link tag="a" class="text-muted" :to="{name:'Signup'}">Need to create an normal account?</router-link>
                </div>
              </div>
            </div>
            <!--SIGNUP TAB-->

          </div>
        </div>
      </div>
      <!-- Body -->
    </div>

    <!-- Footer -->
    <page-footer></page-footer>
    <!-- Footer -->

  </main>
</template>

<script>
  import PageHeader from '../components/PageHeader.vue';
  import PageFooter from '../components/PageFooter.vue';
  import Common from '../mixins/Common'

  export default {

    mixins: [Common],

    components: {PageHeader,PageFooter},

    data() {
      return {
        login_code: '',
      }
    },

    created() {
      app = this;
    },

    mounted() {
      document.title = 'Guest Login';

      //Check errors?
      if(this.$route.query.cd=='code-invalid') {
        this.$eventHub.$emit('ok-modal',{title:'Login Code Invalid',message:"Your login code was not found, please recheck and try again.  If you are still having login issues please contact your mentor."});
      }
    },

    methods: {

      loginCodeEnterKey(e) {
        if(e.keyCode!=13) return;
        this.$refs.ref_login_code.focus();
      },

      loginClicked() {
        if(this.login_code.length!=6) return this.$eventHub.$emit('ok-modal',{title:'Login Error',message:"Please enter a valid login code."});

        // Replace some letters
        let login_code = this.login_code.toUpperCase()
        login_code = login_code.replace('I','1')
        login_code = login_code.replace('O','0')

        this.$eventHub.$emit('loading',true);
        let url = `${this.getApiUrl()}/api/v1/student/login`;
        $.post(url,{login_code}).done(response => {
          if(response.ok==0) {
            this.$eventHub.$emit('loading',false);
            if(response.err.message==null) return this.$eventHub.$emit('ok-modal',{title:'Login Error',message:"Could not login please check your code and try again."});
            else return this.$eventHub.$emit('ok-modal',{title:'Login Error',message:response.err.message});
          }
          else {
            this.login_code = '';
            window.location = '/admin';
          }
        }).fail((xhr,status,err)=>{
          this.$eventHub.$emit('loading',false);
          this.$eventHub.$emit('ok-modal',{title:this.$t("connection_error_title"),message:this.$t("connection_error_message")});
        });
      }

    }

  }
</script>
