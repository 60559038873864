<template>
  <div v-show="loading==true" class="spinnerContainer">
    <div class="spinner-border spinnerElement" role="status"></div>
    <img v-if="image!=null && image.length>0" :src="image" class="spinnerLogo"/>
  </div>
</template>

<style lang="scss" scoped>
  $spinnerSize: 6em;
  $spinnerColor: rgba(0,0,0,0.25);
  $spinnerOverlayColor: rgba(255,255,255,0.75);

  .spinnerLogo {
    position:fixed;
    top:50%;
    left:50%;
    width:50px;
    transform:translate(-50%,-50%);
    image-rendering: auto;
  }
  .spinnerElement {
    width:$spinnerSize;
    height:$spinnerSize;
    color:$spinnerColor;
  }
  .spinnerContainer {
    position: fixed;
    z-index: 1200;
    height: $spinnerSize;
    width: $spinnerSize;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .spinnerContainer:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $spinnerOverlayColor;
  }
</style>

<script>
  export default {

    props: {
      "image":String //Url of an image to put in the spinner
    },

    data() {
      return {
        loading:false
      }
    },

    mounted() {
      //Hide or show spinner
      this.$eventHub.$on('loading',b => {
        this.loading = b;
      })

      //Page changed
      window.addEventListener("pagehide", (e) => {
        this.loading = false;
        return null;
      }, false);
    }
  }
</script>
